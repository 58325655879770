.brand {
    font-family: 'Ruthie', cursive;
    font-size: 2em !important;
    color: #fff !important;
    z-index: 999;
  }
  
  nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32 ease-in-out;
  }
  
  nav.navbar.scrolled {
    padding: 10px 0;
    background-color: #121212;
  }
  
  nav.navbar a.navbar-brand {
    width: 9%;
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
    transition: 0.3s ease-in-out;
  }
  
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover, 
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
  }
  
  span.navbar-text {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    display: inline-block;
    margin-left: 14px;
  }
  
  .social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  .social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }
  
  .social-icon a:hover::before {
    transform: scale(1);
  }
  
  .social-icon a img {
    width: 40%;
    z-index: 1;
    transition: 0.3s ease-in-out;
  }
  
  .social-icon a:hover img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
  }
  
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
  }
  
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid #fff;
  }
  
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: #fff;
      height: 2px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: #fff;
      height: 2px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  }

  .navbar-toggler {
    border: none;
    outline: none;
  }

  .contact {
    margin-left: 18px;
  }

  @media screen and (max-width: 1200px) {
    nav.navbar .navbar-nav .nav-link.navbar-link {
      font-weight: 400;
      color: #fff;
      letter-spacing: 0.8px;
      padding: 0 18px;
      margin: 5px 0;
      font-size: 18px;
      opacity: 0.75;
      text-align: center;
    }

    .navbar-text {
      justify-content: center;
      display: block !important;
    }

    .social-icon {
      display: flex;
      justify-content: center;
      margin: 5px auto;
    }

    .contact {
      margin: 10px auto !important;
      padding: 8px 20px !important;
    }

    .navbar-collapse {
      margin-top: 10px;
    }

    .container {
      padding: 0  18px !important;
      max-width: 100% !important;
    }

    nav.navbar {
      justify-content: center;
      background-color: #121212;
      padding-bottom: 18px;
    }

    .brand {
      margin-left: 18px;
    }

    .navbar-toggler {
      margin-right: 12px;
    }

    .social-icon a { 
      width: 36px;
      height: 36px;
    }

    .social-icon a::before {
      width: 36px;
      height: 36px;
    }
  }
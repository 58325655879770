.contact-section {
    background-image: url('../img/footer-bg.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0;
    padding: 130px 0 100px 0;
}

.contact-section h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-top: 20px;
    display: block;
  }

.contact-section img {
    animation: updown 3s linear infinite;
}

.contact-section form {
    margin-top: 50px;
}

.contact-section form input, .contact-section form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
}

.contact-section form input:focus, .contact-section form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
}

.contact-section form input::placeholder, .contact-section form textarea::placeholder {
    font-weight: 400;
    color: #fff;
}

.contact-section form input:focus::placeholder, .contact-section form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }

  .contact-section form button {
    font-weight: 700;
    color: #000;
    background-color: #fff;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 15px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
    border: 1px solid #fff;
  }

  .contact-section form button span {
    z-index: 1;
    position: relative;
  }

  .contact-section form button:hover {
    color: #fff;
  }
  .contact-section form button::before {
    content: "";
    background-color: #16102c;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }
  .contact-section form button:hover::before {
    width: 100%;
  }
  
  @media screen and (max-width: 1200px) {
    .contact-section {
      padding: 100px 0 100px 0;
    }
    .contact-section h1 {
      font-size: 50px;
    }

    .contact-section form input, .contact-section form textarea {
      font-size: 16px;
    }

    .contact-section form button {
      font-size: 16px;
      padding: 12px 40px;
    }
  }

  @media screen and (max-width: 768px) {
    .contact-section {
        text-align: center;
        padding: 60px 30px 100px 30px;
    }

    .contact-section h1 {
        font-size: 40px;
    }

    .contact-section form input, .contact-section form textarea {
        font-size: 14px;
    }

    .contact-section img {
        max-width: 300px;
    }
  }